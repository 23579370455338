<template>
  <div>
    <v-app-bar app color="primary" >
      <v-btn
        :disabled="!showBackButton"
        fab
        color="secondary"
        dark
        small
        @click="$router.go(-1)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-switch
        v-if="false"
        v-model="$vuetify.theme.dark"
        color="secondary"
        small
        :label="($vuetify.theme.dark ? 'Dark' : 'Ligth')"
      ></v-switch>
      <v-spacer></v-spacer>
      <v-toolbar-title class="font-weight-black headline mr-3">
        Δ
      </v-toolbar-title>
      <v-avatar class="mr-3" color="grey lighten-5" >
        <v-img
          @click="$router.push({ name: 'Home' })"
          src="@/assets/logo.png"
        ></v-img>
      </v-avatar>
      <v-toolbar-title class="font-weight-black headline">
        V
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog v-if="showCartButton"  v-model="$store.state.cartDialogFlag" width="400">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small fab color="secondary" dark v-bind="attrs" v-on="on">
            <v-icon>mdi-cart-plus</v-icon>
          </v-btn>
        </template>
        <CartResume/>
      </v-dialog>
    </v-app-bar>
  </div>
</template>

<script>
import CartResume from '@/components/app/CartResume'
export default {
  components: {
    CartResume
  },
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    showBackButton() {
      return this.$route.name === 'Home' ? false : true
    },
    showCartButton() {
      return this.$route.name === 'Purchase' ? false : true
    }
  }
}
</script>

<style lang="scss" scoped></style>
