var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_vm._v(" Total: "+_vm._s(_vm.orders.reduce(function (previous, current) { return previous + current.importe; }, 0))+"€ ")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"items-per-page":5},scopedSlots:_vm._u([{key:"item.payed",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-checkbox',{attrs:{"label":item.payed ? 'Pagado' : 'No Pagado'},on:{"change":function($event){return _vm.changePayedStatus(item)}},model:{value:(item.payed),callback:function ($$v) {_vm.$set(item, "payed", $$v)},expression:"item.payed"}})],1)]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return _vm._l((item.products),function(product){return _c('span',{key:product.id},[_vm._v(" "+_vm._s(product.name)+" "+_vm._s(product.size)+" "+_vm._s(product.color)+" "+_vm._s(product.amount)+" "),_c('br')])})}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.address.address)+", "+_vm._s(item.address.state)+", "+_vm._s(item.address.CP)+" "),_c('br')])]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-checkbox',{attrs:{"label":item.sent ? 'Enviado' : 'No Enviado'},on:{"change":function($event){return _vm.changeSentStatus(item)}},model:{value:(item.sent),callback:function ($$v) {_vm.$set(item, "sent", $$v)},expression:"item.sent"}})],1)]}},{key:"item.deliveryMethod",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-select',{attrs:{"items":_vm.deliveryMethods},on:{"change":function($event){return _vm.changeDeliveryMethod(item)}},model:{value:(item.deliveryMethod),callback:function ($$v) {_vm.$set(item, "deliveryMethod", $$v)},expression:"item.deliveryMethod"}})],1)]}}])})],1)],1),_c('v-row',[_vm._v(" Apollo 11: "+_vm._s(_vm.apolloOrders)+" "),_c('br'),_vm._v(" Pioneer: "+_vm._s(_vm.pioneerOrders)+" ")]),_c('v-row',[_vm._v(" Total Pagado: "+_vm._s(_vm.payedOrders)+"€ ")]),_c('v-row',[_vm._v(" Total Sin Pagar: "+_vm._s(_vm.notPayedOrders)+"€ ")]),_c('br'),_c('v-row',[_vm._v("emails: "+_vm._s(_vm.emails))]),_c('br'),_c('v-row',[_vm._v("Nombres: "+_vm._s(_vm.names))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{on:{"click":function($event){return _vm.logOut()}}},[_vm._v("logout")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }