<template>
  <v-app>
    <AppBar />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@/components/app/AppBar";
export default {
  components: {
    AppBar
  },
  mounted() {
    this.$vuetify.theme.dark = true;
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Merienda";
  src: local("Merienda"),
    url(./fonts/Merienda/Merienda-Regular.ttf) format("truetype");
}
</style>

