<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="12" lg="10">
        <Orders />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Orders from "@/components/Orders.vue";

export default {
  name: "OrdersView",
  components: {
    Orders
  }
};
</script>
