<template>
  <v-card class="mx-auto" outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <v-container>
          <v-row>
            <div class="text-overline mb-4">Pedido Realizado</div>
          </v-row>
          <v-row>
            <p class="text-h7 mb-1 green--text">
              IBAN: ES17 0049 1622 8320 9037 7425
            </p>
            <br />
          </v-row>

          <v-row>
            <p class="text-h7 mb-1 green--text">Bizum: +34 685 225 299</p>
          </v-row>
          <v-row>
            <p>
              Por favor Realice una transferencia o Bizum indicando en el
              concepto el siguiente número de pedido:
              <br />
              <br />
              <b>{{ 'DV-' + $route.params.timestamp }}</b>
              <br />
              <br />
              seguido de su nombre y apellidos.
              <br />
              <br />
              Importe total: {{ finalPrice }}€
            </p>
          </v-row>
        </v-container>
      </v-list-item-content>

      <v-icon large> mdi-rocket-launch </v-icon>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "MakePayment",
  computed: {
    finalPrice() {
      let totalPrice = this.$store.state.cart.reduce(function(
        accumulator,
        item
      ) {
        return accumulator + item.price * item.amount;
      },
      0);
      if (this.$store.state.cart.length < 2) {
        (totalPrice += 2.95);
      }
      return totalPrice;
    }
  }
};
</script>

<style lang="scss" scoped></style>
