<template>
  <v-form ref="form" v-model="valid" :lazy-validation="true">
    <v-container>
      <v-row align="center">
        <v-col cols="12" sm="12" lg="8">
          <v-text-field
            v-model="customerName"
            :counter="30"
            :rules="nameRules"
            label="Nombre Completo"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="customerBirthDate"
                label="Fecha de nacimiento"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="customerBirthDate"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" lg="5">
          <v-text-field
            v-model="customerEmail"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" lg="3">
          <v-text-field
            v-model="customerPhone"
            :rules="phoneRules"
            label="Teléfono"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" lg="4">
          <v-text-field
            v-model="customerInstagram"
            label="Instagram"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" lg="1">
          <v-text-field
            v-model="address.CP"
            :counter="5"
            :rules="CPrules"
            label="CP"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="4">
          <v-text-field
            v-model="address.address"
            :counter="30"
            :rules="addressrules"
            label="Dirección"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" lg="3">
          <v-text-field
            v-model="address.locality"
            :counter="30"
            :rules="addressrules"
            label="Localidad"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="2">
          <v-select
            v-model="address.state"
            :items="provincias"
            label="Provincia"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" lg="2">
          <v-text-field
            disabled
            v-model="address.country"
            label="País"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" lg="12">
          <v-text-field
            v-model="address.notes"
            label="Notas para entrega"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="4">
          <v-btn
            :disabled="$store.state.cart.length <= 0"
            color="success"
            class="mr-4"
            @click="validate"
          >
            Confirmar Pedido
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" lg="4">
          <v-btn color="error" class="mr-4" @click="reset"> Borrar Todo </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import firebase from "firebase";
export default {
  data: () => ({
    valid: true,
    provincias: [
      "Alava",
      "Albacete",
      "Alicante",
      "Almería",
      "Asturias",
      "Avila",
      "Badajoz",
      "Barcelona",
      "Burgos",
      "Cáceres",
      "Cádiz",
      "Cantabria",
      "Castellón",
      "Ciudad Real",
      "Córdoba",
      "La Coruña",
      "Cuenca",
      "Gerona",
      "Granada",
      "Guadalajara",
      "Guipúzcoa",
      "Huelva",
      "Huesca",
      "Islas Baleares",
      "Jaén",
      "León",
      "Lérida",
      "Lugo",
      "Madrid",
      "Málaga",
      "Murcia",
      "Navarra",
      "Orense",
      "Palencia",
      "Las Palmas",
      "Pontevedra",
      "La Rioja",
      "Salamanca",
      "Segovia",
      "Sevilla",
      "Soria",
      "Tarragona",
      "Santa Cruz de Tenerife",
      "Teruel",
      "Toledo",
      "Valencia",
      "Valladolid",
      "Vizcaya",
      "Zamora",
      "Zaragoza"
    ],
    menu: false,
    customerName: "Enrique",
    customerEmail: "enriquesanbon@gmail.com",
    customerPhone: "659781615",
    customerInstagram: "@EnriqueSanBon",
    customerBirthDate: "",
    nameRules: [
      v => !!v || "Nombre obligatorio",
      v =>
        (v && v.length <= 30) || "El nombre no puede tener mas de 30 caracteres"
    ],
    CPrules: [
      v => !!v || "Código Postal obligatorio",
      v => (v && v.length <= 5) || "El CP no es valido"
    ],
    DNIrules: [
      v => !!v || "DNI necesario",
      v => (v && v.length <= 10) || "DNI no puede tener mas de 10 caracteres"
    ],
    addressrules: [
      v => !!v || "Dirección necesaria",
      v =>
        (v && v.length <= 40) ||
        "La dirección no puede contener mas de 40 caracteres"
    ],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    phoneRules: [
      v => !!v || "E-mail is required",
      v => (v && v.length <= 10) || "Telefono no válido",
      v => /^\d+$/.test(v) || "Telefono no válido"
    ],
    checkbox: false,
    address: {
      CP: "33507",
      address: "Debodes Alcoreda 1",
      country: "España",
      state: "",
      locality: "Caldueño"
    }
  }),

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        console.log("ir a purchase");
        this.purchase();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    purchase() {
      console.log("dentro de purchase");
      var db = firebase.firestore();
      console.log("1");
      var context = this;
      console.log("2");
      var currentDate = new Date()
      var timestamp = currentDate.getTime()
      // Add a new document with a generated id.
      db.collection("orders")
        .add({
          address: context.address,
          customerEmail: context.customerEmail,
          customerInstagram: context.customerInstagram,
          customerName: context.customerName,
          customerBirthDate: context.customerBirthDate,
          customerPhone: context.customerPhone,
          products: context.$store.state.cart,
          orderDate: currentDate,
          timeStamp: timestamp,
          orderPrice: (context.$store.state.cart.length < 2 ? 2.95 : 0),
          payed: false
        })
        .then(docRef => {
          console.log("docRef")
          console.log(docRef)
          console.log("Document written with ID: ", docRef.id);
          console.log('timestamp');
          console.log(timestamp);
          context.$router.push({
            name: "PurchaseCompleted",
            params: { timestamp }
          });
        })
        .catch(error => {
          console.error("Error adding document: ", error);
        });
    }
  }
};
</script>
