import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#f0f0f0',
          secondary: '#42A5F5',
          accent: '#8c9eff',
          error: '#b71c1c',
        },
        dark: {
          primary: '#4d4d4d',
          secondary: '#424242',
          accent: '#8c9eff',
          error: '#b71c1c',
        },
      },
    },
})
