<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" lg="4">
        <CartResume />
      </v-col>
      <v-col cols="12"  sm="8" lg="6">
        <v-card>
          <AddressForm />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CartResume from '@/components/app/CartResume'
import AddressForm from '@/components/AddressForm'

export default {
  name: 'Purchase',
  components: {
    CartResume,
    AddressForm
  }
}
</script>

<style lang="scss" scoped></style>
