<template>
  <v-card elevation="2">
    <v-form ref="form" v-model="valid">
      <v-container>
        <v-row justify="center">
          <v-col cols="10" md="10">
            <v-text-field
              v-model="email"
              label="email"
              required
              :rules="rules"
            ></v-text-field>
          </v-col>
          <v-col cols="10" md="10">
            <v-text-field
              v-model="password"
              type="password"
              label="Contraseña"
              :rules="rules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn @click="validate"> Login </v-btn>
        </v-row>
      </v-container>
    </v-form>
    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      email: "",
      password: "",
      valid: false,
      rules: [v => !!v || "Campo Obligatorio"],
      snackbar: false,
      snackbarText: `Login Incorrecto`,
    };
  },
  methods: {
    validate() {
      var context = this
      if (this.$refs.form.validate()) {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(userCredential => {
            // Signed in
            var user = userCredential.user;
            console.log("usuario logueado");
            console.log(user);
            this.$router.push({ name: 'Orders' })
            // ...
          })
          .catch(error => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log("error login");
            console.log(errorCode);
            console.log(errorMessage);
            context.snackbar = true
          });
        console.log("neperio");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>