<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="12" lg="4">
        <MecenasInfo />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import MecenasInfo from '@/components/MecenasInfo'

export default {
  name: 'Purchase',
  components: {
    MecenasInfo,
  }
}
</script>

<style lang="scss" scoped></style>