import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import firebase from 'firebase'
Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyBYhZbCQ1dW46brPxfrERWZFPEfDVoI6xo",
  authDomain: "deltauve.firebaseapp.com",
  projectId: "deltauve",
  storageBucket: "deltauve.appspot.com",
  messagingSenderId: "1024970456113",
  appId: "1:1024970456113:web:43d9a3b5d88d04d242e910",
  measurementId: "G-7KSP8YZ352"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
