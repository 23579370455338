<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">
            {{ product.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Talla: {{ product.size }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Color: {{ product.color }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Cantidad: {{ product.amount }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Precio/Unidad: {{ product.price }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-btn @click="deleteFromCart(product)" icon color="red">
              <v-icon dark>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-col>
      <v-col cols="4">
        <v-list-item-avatar tile size="110" color="grey">
          <v-img :src="imagePath"></v-img>
        </v-list-item-avatar>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import firebase from 'firebase'

export default {
  props: {
    product: {
      type: Object
    }
  },
  mounted() {
    this.getCloudImages()
  },
  data() {
    return {
      imagePath: ''
    }
  },
  methods: {
    deleteFromCart(product) {
      console.log('eliminar producto')
      console.log(product)
      console.log('buscar producto en array productos')
      const foundIndex = this.$store.state.cart.findIndex(
        element => element === product
      )
      console.log('foundIndex')
      console.log(foundIndex)
      var newCart = this.$store.state.cart
      newCart.splice(foundIndex, 1)
      this.$store.commit('setCart', newCart)
    },
    getCloudImages() {
      var context = this
      console.log('getCloudImages')
      // Get a reference to the storage service, which is used to create references in your storage bucket
      var storage = firebase.storage()
      // Create a storage reference from our storage service
      var storageRef = storage.ref()

      // Obtener la imagen de portada
      storageRef
        .child(
          '/models/' +
            this.product.productId +
            '/miniatura-' +
            this.product.color +
            '.png'
        )
        .getDownloadURL()
        .then(function(url) {
          context.imagePath = url
          console.log('found url')
          console.log(url)
        })
        .catch(function(error) {
          // Handle any errors
          console.log('error in getCloudImages (foto portada)')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
