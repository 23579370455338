<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 16 : 2" class="py-12 px-4" flat>
      <v-card-title
        class="text-h4 justify-center font-weight-black text-uppercase"
        v-text="product.name"
        @click="$router.push({ name: 'Product', params: { id: product.firestoreId, product: product } })"
      ></v-card-title>
      <v-card-text
        class="subtitle-1"
        v-text="product.year + ' - ' + product.company"
      >
      </v-card-text>
      <v-img
        height="600"
        :src="imagePath"
        @click="$router.push({ name: 'Product', params: { id: product.firestoreId, product: product } })"
      />
      <v-card-text class="subtitle-1" v-text="product.title" />

      <v-card-actions>
        <p class="font-weight-bold">{{ product.price }} €</p>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="
            $router.push({
              name: 'Product',
              params: { id: product.firestoreId, product: product },
            })
          "
        >
          Ver Producto
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <v-card-text
          v-show="show"
          class="subtitle-1"
          v-text="product.description"
        />
      </v-expand-transition>
    </v-card>
  </v-hover>
</template>

<script>
import firebase from "firebase";
export default {
  props: {
    product: {
      type: Object,
    },
  },
  mounted() {
    this.getCloudImages();
  },
  data() {
    return {
      imagePath: "",
      show: false,
    };
  },
  methods: {
    getCloudImages() {
      var context = this;
      console.log("getCloudImages");
      // Get a reference to the storage service, which is used to create references in your storage bucket
      var storage = firebase.storage();
      // Create a storage reference from our storage service
      var storageRef = storage.ref();

      // Obtener la imagen de portada
      storageRef
        .child("/models/" + this.product.firestoreId + "/portada.jpeg")
        .getDownloadURL()
        .then(function (url) {
          context.imagePath = url;
          console.log("found url");
          console.log(url);
        })
        .catch(function (error) {
          // Handle any errors
          console.log("error in getCloudImages (foto portada)");
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
