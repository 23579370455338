<template>
  <v-card>
    <v-container v-if="product">
      <v-row justify="center">
        <v-col :cols="isMobileFlag ? 12 : 7">
          <v-carousel
            class="fill-height"
            :height="isMobileFlag ? 400 : 700"
            hide-delimiters
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item"
              reverse-transition="fade-transition"
              transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col :cols="isMobileFlag ? 12 : 5">
          <v-card-title
            >{{ product.name }} - {{ product.price }}€ (VENTA FINALIZADA)
          </v-card-title>
          <v-card-text
            class="subtitle-1"
            v-text="product.year + ' - ' + product.company"
          >
          </v-card-text>
          <v-row justify="center">
            <v-col cols="10">
              <v-row>
                <v-col cols="12" sm="12" :lg="selectedColor ? 5 : 12">
                  <v-select
                    @change="cartAddedFlag = false"
                    dense
                    disabled
                    v-model="selectedColor"
                    :items="colors"
                    label="Color"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  v-if="selectedColor"
                  :lg="selectedSize ? 4 : 6"
                >
                  <v-select
                    @change="cartAddedFlag = false"
                    dense
                    disabled
                    v-model="selectedSize"
                    :items="selectedColorStock"
                    label="Talla"
                  ></v-select>
                </v-col>
                <v-col v-if="selectedSize" cols="12" sm="12" lg="3">
                  <v-select
                    @change="cartAddedFlag = false"
                    dense
                    disabled
                    v-model="selectedAmount"
                    :items="amounts"
                    label="Cantidad"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-btn
                    :disabled="cartAddedFlag || !selectedAmount || true"
                    @click="addToCart()"
                    color="primary"
                    block
                  >
                    <v-icon v-if="cartAddedFlag">mdi-check</v-icon>
                    <v-icon v-if="!cartAddedFlag">mdi-cart-plus</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="8">
                  <v-btn
                    :disabled="!selectedAmount || true"
                    color="primary"
                    block
                    @click="
                      dialog = false;
                      if (!cartAddedFlag) {
                        addToCart();
                      }
                      $router.push({ name: 'Purchase' });
                    "
                  >
                    Comprar ya
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card-text class="subtitle-2" v-text="product.description">
              </v-card-text>
            </v-col>
          </v-row>
          <v-btn text @click="show = !show">
            <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            Tallas y materiales
          </v-btn>
          <v-expand-transition>
            <v-row v-show="show">
              <v-col cols="6" sm="6" lg="4">
                <v-img src="@/assets/tallasEsquema.jpeg"></v-img>
              </v-col>
              <v-col cols="6" sm="6" lg="8">
                <v-card-text> Materiales: 100% Algodón </v-card-text>
              </v-col>
              <v-col cols="12" sm="12" lg="12">
                <v-img src="@/assets/tallas.jpeg"></v-img>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12"> </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row justify="center">
        <v-progress-circular
              :size="200"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { isMobile } from "@/services/utils";
import firebase from "firebase";
export default {
  data() {
    return {
      isMobileFlag: false,
      items: [],
      stock: [],
      colors: [],
      amounts: [1, 2, 3, 4],
      selectedColor: "",
      selectedAmount: 0,
      selectedSize: "",
      cartAddedFlag: false,
      show: false,
      product: {},
    };
  },
  computed: {
    selectedColorStock() {
      var availableSizes = [];
      if (this.selectedColor) {
        var stockOfThisColor = this.stock.find(
          (element) => element.color === this.selectedColor
        );
        Object.entries(stockOfThisColor.sizeAmount).map((item) => {
          if (item[1] > 0) {
            availableSizes.push(item[0]);
          }
        });
        return availableSizes;
      } else {
        return [];
      }
    },
  },
  async mounted() {
    if (!this.$route.params.product) {
      this.product = await this.getProductInfo();
    } else {
      this.product = this.$route.params.product;
    }
    this.isMobileFlag = isMobile();
    this.getCatalogImages();
    this.getStock();
  },
  methods: {
    getCatalogImages() {
      var context = this;
      // Get a reference to the storage service, which is used to create references in your storage bucket
      var storage = firebase.storage();
      // Create a storage reference from our storage service
      var storageRef = storage.ref();

      // Obtener la lista de imagenes del producto
      // Create a reference under which you want to list
      var listRef = storageRef.child(
        "/models/" + this.$route.params.id + "/galery"
      );

      // Find all the prefixes and items.
      listRef
        .listAll()
        .then(function (res) {
          res.prefixes.forEach(function (/*folderRef*/) {

            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
          });
          res.items.forEach(function (itemRef) {
            // All the items under listRef.
            itemRef.getDownloadURL().then(function (url) {
              context.items.push(url);
            });
          });
        })
        .catch(function (error) {
          console.log("error in getCloudImages (fotos producto)");
          console.log(error);
          // Uh-oh, an error occurred!
        });
    },
    getStock() {
      var db = firebase.firestore();
      var context = this;
      this.stock = [];
      db.collection("stock")
        .where("productId", "==", this.$route.params.id)
        .onSnapshot((querySnapshot) => {
          context.stock = [];
          querySnapshot.forEach((doc) => {
            context.stock.push(doc.data());
            context.colors.push(doc.data().color);
          });
          console.log("Current stock: ", context.stock);
        });
    },
    getProductInfo() {
      var context = this;
      var db = firebase.firestore();
      var docRef = db.collection("models").doc(this.$route.params.id);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            context.product = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            context.$router.push({ name: 'Home' })
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    async addToCart() {
      var purchaseProduct = {
        productId: this.$route.params.id,
        name: this.product.name,
        size: this.selectedSize,
        amount: this.selectedAmount,
        color: this.selectedColor,
        id: new Date().getTime(),
        price: this.product.price,
      };
      var newCart = this.$store.state.cart;
      newCart.push(purchaseProduct);
      await this.$store.commit("setCart", newCart);
      this.cartAddedFlag = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
