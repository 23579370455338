<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <MakePayment />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MakePayment from '@/components/MakePayment'

export default {
  name: 'PurchaseCompleted',
  components: {
    MakePayment
  }
}
</script>

<style lang="scss" scoped></style>
