import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ProductDetail from '../views/ProductDetail.view.vue'
import Purchase from '../views/Purchase.view.vue'
import MecenasView from '../views/Mecenas.view.vue'
import LoginView from '../views/Login.view.vue'
import OrdersView from '../views/Orders.view.vue'
import PurchaseCompleted from '../views/PurchaseCompleted.view.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },{
    path: '/login',
    name: 'Login',
    component: LoginView
  },{
    path: '/orders',
    name: 'Orders',
    component: OrdersView
  },{
    path: '/mecenas',
    name: 'Mecenas',
    component: MecenasView
  },{
    path: '/product/:id',
    name: 'Product',
    component: ProductDetail
  },{
    path: '/purchase',
    name: 'Purchase',
    component: Purchase
  },{
    path: '/purchaseCompleted',
    name: 'PurchaseCompleted',
    component: PurchaseCompleted
  },{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
