<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        Total:
        {{
          orders.reduce(function (previous, current) {
            return previous + current.importe;
          }, 0)
        }}€
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="orders"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.payed="{ item }">
            <v-container class="px-0" fluid>
              <v-checkbox
                v-model="item.payed"
                @change="changePayedStatus(item)"
                :label="item.payed ? 'Pagado' : 'No Pagado'"
              ></v-checkbox>
            </v-container>
          </template>
          <template v-slot:item.products="{ item }">
            <span v-for="product in item.products" v-bind:key="product.id">
              {{ product.name }}
              {{ product.size }}
              {{ product.color }}
              {{ product.amount }}
              <br />
            </span>
          </template>
          <template v-slot:item.address="{ item }">
            <span>
              {{ item.address.address }}, {{ item.address.state }},
              {{ item.address.CP }}
              <br />
            </span>
          </template>
          <template v-slot:item.sent="{ item }">
            <v-container class="px-0" fluid>
              <v-checkbox
                v-model="item.sent"
                @change="changeSentStatus(item)"
                :label="item.sent ? 'Enviado' : 'No Enviado'"
              ></v-checkbox>
            </v-container>
          </template>
          <template v-slot:item.deliveryMethod="{ item }">
            <v-container class="px-0" fluid>
              <v-select
                :items="deliveryMethods"
                v-model="item.deliveryMethod"
                @change="changeDeliveryMethod(item)"
              ></v-select>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      Apollo 11: {{ apolloOrders }}
      <!--
      <div
        v-for="(value, propertyName) in apolloOrders"
        v-bind:key="propertyName"
      >
        <p>{{ propertyName }}: {{ value }}</p> <br />
      </div>
      -->
      <br />
      Pioneer: {{ pioneerOrders }}
    </v-row>
    <v-row> Total Pagado: {{ payedOrders }}€ </v-row>
    <v-row> Total Sin Pagar: {{ notPayedOrders }}€ </v-row>
    <br />
    <v-row>emails: {{ emails }}</v-row>
    <br />
    <v-row>Nombres: {{ names }}</v-row>
    <v-row>
      <v-col cols="12" md="12"> <v-btn @click="logOut()">logout</v-btn></v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      headers: [
        { text: "Id", value: "UUID" },
        { text: "Nombre", value: "customerName" },
        { text: "Email", value: "customerEmail" },
        { text: "Instagram", value: "customerInstagram" },
        { text: "Phone", value: "customerPhone" },
        { text: "Pagado?", value: "payed" },
        { text: "Importe", value: "importe" },
        { text: "Products", value: "products" },
        { text: "Dirección", value: "address" },
        { text: "Enviado?", value: "sent" },
        { text: "Metodo de entrega", value: "deliveryMethod" }
      ],
      orders: [],
      deliveryMethods: [
        "Envio Normal",
        "Contra Reembolso",
        "En mano Quique",
        "En mano Luis",
        "En mano Julia"
      ]
    };
  },
  mounted() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        console.log("current User");
        console.log(uid);
        this.getOrders();

        // ...
      } else {
        // User is signed out
        this.$router.push({ name: "Login" });
        // ...
      }
    });
  },
  methods: {
    getOrders() {
      try {
        var db = firebase.firestore();
        db.collection("orders").onSnapshot(querySnapshot => {
          this.orders = [];

          querySnapshot.forEach(doc => {
            console.log(doc);
            var order = doc.data();
            order.UUID = doc.id;
            order.importe = order.products.reduce(function(previous, current) {
              return previous + current.price * current.amount;
            }, 0);
            this.orders.push(order);
          });
          console.log("Current orders: ", this.orders);
        });
      } catch (error) {
        console.log("error al listar los pedidos");
        console.log(error);
      }
    },
    changePayedStatus(item) {
      console.log(item);
      var db = firebase.firestore();

      // Create a reference to the SF doc.
      var sfDocRef = db.collection("orders").doc(item.UUID);

      // Uncomment to initialize the doc.
      // sfDocRef.set({ population: 0 });

      return db
        .runTransaction(transaction => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then(sfDoc => {
            if (!sfDoc.exists) {
              throw "Document does not exist!";
            }

            // Add one person to the city population.
            // Note: this could be done without a transaction
            //       by updating the population using FieldValue.increment()
            transaction.update(sfDocRef, { payed: !sfDoc.data().payed });
          });
        })
        .then(() => {
          console.log("Transaction successfully committed!");
        })
        .catch(error => {
          console.log("Transaction failed: ", error);
        });
    },
    changeSentStatus(item) {
      console.log(item);
      var db = firebase.firestore();

      // Create a reference to the SF doc.
      var sfDocRef = db.collection("orders").doc(item.UUID);

      // Uncomment to initialize the doc.
      // sfDocRef.set({ population: 0 });

      return db
        .runTransaction(transaction => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then(sfDoc => {
            if (!sfDoc.exists) {
              throw "Document does not exist!";
            }

            // Add one person to the city population.
            // Note: this could be done without a transaction
            //       by updating the population using FieldValue.increment()
            transaction.update(sfDocRef, { sent: !sfDoc.data().sent });
          });
        })
        .then(() => {
          console.log("Transaction successfully committed!");
        })
        .catch(error => {
          console.log("Transaction failed: ", error);
        });
    },

    changeDeliveryMethod(item) {
      console.log(item);
      var db = firebase.firestore();

      // Create a reference to the SF doc.
      var sfDocRef = db.collection("orders").doc(item.UUID);

      // Uncomment to initialize the doc.
      // sfDocRef.set({ population: 0 });

      return db
        .runTransaction(transaction => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then(sfDoc => {
            if (!sfDoc.exists) {
              throw "Document does not exist!";
            }

            // Add one person to the city population.
            // Note: this could be done without a transaction
            //       by updating the population using FieldValue.increment()
            transaction.update(sfDocRef, {
              deliveryMethod: item.deliveryMethod
            });
          });
        })
        .then(() => {
          console.log("Transaction successfully committed!");
        })
        .catch(error => {
          console.log("Transaction failed: ", error);
        });
    },
    logOut() {
      firebase
        .auth()
        .signOut()
        .then(function() {
          // Sign-out successful.
          console.log("logout correcto");
          this.$router.push({ name: "Login" });
        })
        .catch(function(error) {
          // An error happened.
          console.log("error en logout");
          console.log(error);
          this.$router.push({ name: "Login" });
        });
    }
  },
  computed: {
    pioneerOrders() {
      var pioneerOrders = {
        S_white: 0,
        M_white: 0,
        L_white: 0,
        XL_white: 0,
        "2XL_white": 0,
        "3XL_white": 0,
        S_sand: 0,
        M_sand: 0,
        L_sand: 0,
        XL_sand: 0,
        "2XL_sand": 0,
        "3XL_sand": 0
      };
      console.log(pioneerOrders);
      this.orders.map(function(order) {
        if (order.products) {
          order.products.map(function(product) {
            if (product.productId == "rvJiwSLZ0Ai4NeRnsK4Z") {
              pioneerOrders[product.size + "_" + product.color] +=
                product.amount;
            }
          });
        }
      });
      return pioneerOrders;
    },
    apolloOrders() {
      var pioneerOrders = {
        S_black: 0,
        M_black: 0,
        L_black: 0,
        XL_black: 0,
        "2XL_black": 0,
        "3XL_black": 0,
        S_grey: 0,
        M_grey: 0,
        L_grey: 0,
        XL_grey: 0,
        "2XL_grey": 0,
        "3XL_grey": 0
      };
      console.log(pioneerOrders);
      this.orders.map(function(order) {
        if (order.products) {
          order.products.map(function(product) {
            if (product.productId == "VR61rJs3beu1YL3c1UIy") {
              pioneerOrders[product.size + "_" + product.color] +=
                product.amount;
            }
          });
        }
      });
      return pioneerOrders;
    },
    emails() {
      var emails = "";
      this.orders.map(function(order) {
        console.log("emails");
        console.log(order);
        if (order.customerEmail) {
          emails = emails + order.customerEmail + ", ";
        }
      });
      return emails;
    },
    names() {
      var names = "";
      this.orders.map(function(order) {
        console.log("emails");
        console.log(order);
        if (order.customerName) {
          names = names + order.customerName + ", ";
        }
      });
      return names;
    },
    payedOrders() {
      var totalPayed = this.orders.reduce(function(previous, current) {
        console.log("current");
        console.log(current);
        console.log("previous");
        console.log(previous);
        if (current.payed) {
          return previous + current.importe;
        } else {
          return previous;
        }
      }, 0);
      return totalPayed;
    },
    notPayedOrders() {
      var totalPayed = this.orders.reduce(function(previous, current) {
        console.log("current");
        console.log(current);
        console.log("previous");
        console.log(previous);
        if (!current.payed) {
          return previous + current.importe;
        } else {
          return previous;
        }
      }, 0);
      return totalPayed;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>