<template>
  <v-card>
    <v-list-item class="text-overline" three-line>
      Mi Pedido - {{ $store.state.cart.length }} Productos
    </v-list-item>
    <v-list-item
      v-for="item in $store.state.cart"
      v-bind:key="item.id"
      three-line
    >
      <CartProduct :product="item" />
    </v-list-item>

    <v-card-actions>
      <v-list-item>
        Precio: {{ finalPrice }}€ <br />
        {{(finalPrice > 30 ? 'Envío Gratis' : 'Envío 2,95€ (gratis a partir de 2 productos')}}
      </v-list-item>
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        text
        v-if="showPurchaseButton"
        :disabled="$store.state.cart.length <= 0"
        @click="
          $store.commit('setCartDialogFlag', false)
          $router.push({ name: 'Purchase' })
        "
      >
        Comprar<v-icon>mdi-rocket-launch</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CartProduct from '@/components/app/CartProduct'

export default {
  components: {
    CartProduct
  },
  computed: {
    showPurchaseButton() {
      return this.$route.name === 'Purchase' ? false : true
    },
    finalPrice() {
      let totalPrice = this.$store.state.cart.reduce(function(
        accumulator,
        item
      ) {
        return accumulator + item.price * item.amount
      },
      0)
      return totalPrice
    }
  }
}
</script>

<style lang="scss" scoped></style>
