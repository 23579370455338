<template>
  <div>
    <section id="hero">
      <v-row no-gutters>
        <v-img aspect-ratio="2.1" src="@/assets/home.gif">
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-1',
                    ]"
                    class="description"
                  >
                    SI DE VERDAD ESTAMOS SOLOS, CUÁNTO ESPACIO DESAPROVECHADO.
                  </span>

                  <br />
                  <br />

                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
                    ]"
                    class="font-weight-black"
                  >
                    DELTAUVE
                  </span>
                  <br />
                  <br />
                  <div id="timer" class="timer">
                    <!--  Timer Component  -->
                    <Timer
                      starttime="Jul 10, 2021 00:00:00"
                      endtime="Jul 20, 2021 23:59:59"
                      trans='{  
         "day":"Días",
         "hours":"Horas",
         "minutes":"Minutos",
         "seconds":"Segundos",
         "expired":"Preventa terminada.",
         "running":"Preventa abierta durante:",
         "upcoming":"La preventa comenzará en:",
         "status": {
            "expired":"Expired",
            "running":"El 20 de Julio de 1969 el ser humano llegó a la Luna. Que mejor día para cerrar la preventa de nuestras primeras camisetas.",
            "upcoming":"Future"
           }}'
                    ></Timer>
                    <!--  End! Timer Component  -->
                  </div>
                </v-col>

                <v-btn
                  class="align-self-end"
                  fab
                  outlined
                  @click="$vuetify.goTo('#models')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>
    <section id="models">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">VENTANA DE LANZAMIENTO</h2>
        <v-row class="white--text mx-auto" justify="center">
          <v-col cols="8">
            <span class="'display-3'"
              >El 20 de Julio de 1969 el ser humano llegó a la Luna. El 20 de
              Julio de 2021 ΔV completó con éxito su primera misión lanzando dos
              sondas espaciales al espacio desconocido. Aquí podrás encontrar
              las camisetas oficiales de la misión.</span
            >
          </v-col>
        </v-row>
        <v-row class="white--text mx-auto" justify="center">
          <v-col
            v-for="item in models"
            v-bind:key="item.id"
            cols="12"
            sm="10"
            lg="4"
          >
            <product-card :product="item"></product-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section class="text-center" id="stats">
      <v-parallax
        :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
        src="@/assets/motor.jpg"
      >
        <v-container class="text-center">
          <h1 class="display-3 font-weight-bold mb-9">PREVENTA FINALIZADA</h1>
          <v-row class="mx-auto">
            <v-col
              v-for="[value, title] of stats"
              :key="title"
              cols="12"
              md="6"
            >
              <div class="text-center">
                <div
                  class="display-3 font-weight-black mb-4"
                  v-text="value"
                ></div>

                <div
                  class="title font-weight-regular text-uppercase"
                  v-text="title"
                ></div>
              </div>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <br />
          <v-btn
            color="white"
            elevation="8"
            large
            outlined
            plain
            @click="$router.push({ name: 'Mecenas' })"
          >
            VER MECENAS
          </v-btn>
        </v-container>
      </v-parallax>
    </section>

    <section id="blog">
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          HISTORIA DE NUESTROS DISEÑOS
        </h2>

        <v-responsive class="mx-auto mb-12" width="56"> </v-responsive>
        <v-row justify="center">
          <v-col
            v-for="({ src, text, title, href }, i) in articles"
            :key="i"
            cols="12"
            md="8"
          >
            <v-img :src="src" class="mb-4" max-width="100%"></v-img>

            <h3
              class="font-weight-black mb-4 text-uppercase"
              v-text="title"
            ></h3>

            <div class="title font-weight-light mb-5" v-text="text"></div>

            <v-btn class="ml-n4 font-weight-black" text :href="href">
              Continua leyendo
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section id="about-me">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">SOBRE DELTA UVE</h2>

        <v-responsive class="mx-auto mb-8" width="56"> </v-responsive>

        <v-responsive
          class="mx-auto title font-weight-light mb-8"
          max-width="720"
        >
          "Somos una marca de ropa dedicada a rendir homenaje a los mayores
          hitos de la carrera espacial y la exploración del espacio. Nuestros
          productos van enfocados a apasionados de la ciencia y el espacio.
          Todos nuestros diseños son originales."
        </v-responsive>
        <v-responsive
          class="mx-auto title font-weight-light mb-8"
          max-width="720"
        >
          "⌇⟟ ⎅⟒ ⎐⟒⍀⎅⏃⎅ ⟒⌇⏁⏃⋔⍜⌇ ⌇⍜⌰⍜⌇, ☊⎍⏃⋏⎅⍜ ⟒⌇⌿⏃☊⟟⍜ ⎅⟒⌇⏃⌿⍀⍜⎐⟒☊⊑⏃⎅⍜"
        </v-responsive>
        <v-avatar class="elevation-12 mb-12" size="128">
          <v-img src="@/assets/logo.png"></v-img>
        </v-avatar>

        <div></div>
      </v-container>

      <div class="py-12"></div>
    </section>

    <v-footer class="justify-center" color="primary" height="100">
      <div
        class="title font-weight-light grey--text text--lighten-1 text-center"
      >
        &copy; {{ new Date().getFullYear() }} — Hecho con 💜 por Enrique Sánchez
      </div>
    </v-footer>
  </div>
</template>

<script>
import firebase from "firebase";
import ProductCard from "@/components/ProductCard";
import Timer from "@/components/Timer";
export default {
  name: "MainPage",
  components: { ProductCard, Timer },
  mounted() {
    this.getModels();
  },
  data() {
    return {
      articles: [
        {
          src:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Pioneer_plaque.svg/1280px-Pioneer_plaque.svg.png",
          title: "La placa Pioneer",
          href: "https://es.wikipedia.org/wiki/Placa_de_la_Pioneer",
          text:
            "Las placas de la Pioneer son un par de planchas metálicas que fueron colocadas a bordo de las sondas espaciales Pioneer 10 y Pioneer 11 con un mensaje visual. ",
        },
      ],
      models: [],
      stats: [
        ["Аполлoн-11", "37 VENDIDAS"],
        ["Piooner", "15 VENDIDAS"],
      ],
    };
  },
  methods: {
    getModels() {
      console.log("getModels");

      var db = firebase.firestore();
      db.collection("models")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            var element = doc.data();
            element.firestoreId = doc.id;
            this.models.push(element);
          });
        });
    },
  },
};
</script>
<style>
.description {
  font-family: "Merienda", Helvetica, Arial;
  font-size: 20px;
}
</style>