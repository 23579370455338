<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col :cols="12"  sm="12" lg="10">
          <ProductDetail />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductDetail from '@/components/ProductDetail.vue'
import {isMobile} from '@/services/utils'
export default {
  name: 'Home',
  components: {
    ProductDetail
  },
  data() {
    return {
      isMobileFlag: false
    }
  },
  mounted(){
    this.isMobileFlag = isMobile()
  }
}
</script>
