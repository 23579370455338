<template>
  <v-card class="mx-auto">
    <v-img src="@/assets/mecenas.jpg" height="300px"></v-img>

    <v-card-title> Hazte mecenas </v-card-title>

    <v-card-subtitle>
      Para convertirte en mecenas lo unico que tienes que hacer es apoyarnos en
      el lanzamiento de nuestra primera misión comprando una de nuestras
      primeras camisetas. <br />
      <br />

      Los mecenas serán los que nos ayuden a decidir futuras misiones eligiendo
      temáticas para diseños, nuevos productos etc. <br /><br />

      Por ultimo acuñaremos un NFT asociado a cada camiseta y se lo enviaremos a
      cada mecenas convirtiendo cada camiseta en un elemento único de una tirada
      limitada.
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        href="https://www.youtube.com/watch?v=YKRpRmnIN_g"
        color="orange lighten-2"
        text
      >
        Que es un NFT?
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>

    <v-divider></v-divider>
    <v-container>
      <v-row>
        <v-card-title> Mecenas: </v-card-title>
        <v-card-text>
          Holy - Sputnik 1 <br />
          Eva Cabal- Explorer 1<br />
          María Sánchez - Luna 1 <br />
          Pedro Ruiz - Vanguard 2 <br />
          Javier Recio - Vostok 1 <br />
          Lucia Recio. - Mercury Redstone 3<br />
          Carmen Rozas - Venera 1 <br />
          Mónica Melero. - Gemini 6 <br />
          Jorge Carrasco. - Cosmos 186<br />
          Charo - Soyuz 4<br />
          María Rojo - Soyuz 5<br />
          Andy B. - Apolo 9<br />
          Jose C. - Apolo 10<br />
          Brigui - Apolo 11 <br />
          Alba - Apolo 12<br />
          Osqui - Apolo 13<br />
          Carlos Almansa. - Soyuz 9<br />
          Andrea Rodriguez y Nacho Redondo. - Apolo 14<br />
          Juan Díaz - Soyuz 10<br />
          Pablo García. - Soyuz 11<br />
          Manolo Ortiz - Pioneer 11<br />
          Gloria del Valle - Salyut 1<br />
          Jesús Ruiz-Ayúcar - Mariner 9<br />
          Carlos Suero - Mars 2<br />
          Pilar Requena - Mars 3<br />
          Víctor Sanz - Apolo 15<br />
          Daniel Santaella - Pioneer 10<br />
          Fran Aguilera Matas - Mariner 10<br />
          Emilio Moreno - Venera 9<br />
          Mariana Buquerín - Viking 1<br />
          Luis Carlos Pariente - Helios-B<br />
          Pablo Gomez - Voyager 2<br />
          Roberto Sánchez Fernández - New Horizons<br />
          Cristina Pozo - Venera 11<br />
          Alberto Ruiz - Vega 1<br />
          Javier Muñoz - Sakigake<br />
          Rafa - Phobos 1 <br />
          Oscar Ruiz - NEAR Shoemaker<br />
          Daniel Tabas - Mars Pathfinder <br />
          Serigio Ruiz - Cassini–Huygens<br />
          Miguel Jenaro - Stardust<br />
           <br />

        </v-card-text>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      show: false
    };
  }
};
</script>

<style lang="scss" scoped>
</style>